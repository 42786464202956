import { createStore } from 'vuex';
import auth from './modules/auth/auth';
import actions from './modules/actions/actions';
import themeLayout from './modules/themeLayout/actionCreator';
import createPersistedState from 'vuex-persistedstate'
import chartContent from './modules/chartContent/actionCreator';

export default createStore({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    auth,
    themeLayout,
    actions,
    chartContent
  },
});
