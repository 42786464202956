const routes = [
    {
        path: '/',
        name: 'onboarding',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/view/onboarding/Onboarding.vue'),
    },

    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "Products" */ '@/view/products/Products.vue'),
    },

    {
        path: '/reviews',
        name: 'reviews',
        component: () => import(/* webpackChunkName: "Reviews" */ '@/view/reviews/Reviews.vue'),
    },

    {
        path: '/reports/visits_behaviour',
        name: 'visits_behaviour',
        component: () => import(/* webpackChunkName: "Reports" */ '@/view/reports/visits_behaviour/Index.vue'),
    },

    {
        path: 'reports/recommendation_usage',
        name: 'recommendation_usage',
        component: () => import(/* webpackChunkName: "Reports" */ '@/view/reports/recommendation_usage/Index.vue'),
    },

    {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Users" */ '@/view/users/Index.vue'),
    },

    {
        path: 'users/create',
        name: 'users_create',
        component: () => import(/* webpackChunkName: "Users" */ '@/view/users/CreateUserComponent.vue'),
    },

    {
        path: 'users/edit/:id',
        name: 'users_edit',
        component: () => import(/* webpackChunkName: "Users" */ '@/view/users/EditUserComponent.vue')
    },

    {
        path: 'billing',
        name: 'billing',
        component: () => import(/* webpackChunkName: "Reports" */ '@/view/billing/Index.vue')
    },

    {
        path: 'reports/top',
        name: 'most_interactions',
        component: () => import(/* webpackChunkName: "Reports" */ '@/view/reports/most_interactions/Index.vue')
    },
    
    {
        path: 'attributes_maps',
        name: 'attributes_maps',
        component: () => import(/* webpackChunkName: "Attribute maps"*/ '@/view/attributes_maps/Index.vue')
    },

    {
        path: 'reports/behavior',
        name: 'reports_behavior',
        component: () => import(/* webpackChunkName: "Reports"*/ '@/view/reports/behavior/Index.vue')
    },

    {
        path: 'reports/sales',
        name: 'reports_sales',
        component: () => import(/* webpackChunkName: "Reports"*/ '@/view/reports/sales/Index.vue')
    },
];
export default routes;
