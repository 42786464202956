import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
//import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
import VueHotjar from 'vue-hotjar-next'

app.config.productionTip = false;
app.config.globalProperties.$router = router;
app.config.globalProperties.$store = store;

require('./utility/alerts.js')
require('./services/Api')

window.isLoading = false

app.use(store);
app.use(router);

if (process.env.VUE_APP_ENV === 'production') {
    app.use(VueHotjar, {
        id: 2737229,
        isProduction: true,
        snippetVersion: 6
    });
}

app.mount('#app');
