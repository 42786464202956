import { createWebHistory, createRouter } from 'vue-router';
import dashboardRoutes from './dashboardRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...dashboardRoutes],
    meta: { auth: false },
  },

  {
    name: 'Auth',
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
  
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
    meta: { auth: true },
  },

  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: "404" */ '@/view/static/TermsAndConditions.vue'),
    meta: { auth: true },
  }
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, store);
  if (to.meta.auth && store.state.auth.token) {
    next({ to: '/' });
  } else if (! to.meta.auth && !store.state.auth.token) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
