import axios from 'axios';
import store from '@/vuex/store';
import router from '../routes/protectedRoute';

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

Api.interceptors.request.use(config => {
  config.headers.Authorization = `${'Bearer' + ' '}${store.state.auth.token}`

  return config
}, error => Promise.reject(error));

Api.interceptors.response.use(response => response, error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2x
    if (error.response.status === 422) {
      if (typeof error.response.data.errors === 'object') {
        const errors = Object.values(error.response.data.errors)
        errors.forEach((error) => {
          window.showAlert('warning', 'Warning', error.message);
        });
        store.dispatch("actions/setLoading", false);
      } else {
        window.showAlert("warning", "Warning", error.response.data.message);
        store.dispatch("actions/setLoading", false);
      }
    } else if (error.response.status === 401) {
      window.showAlert("warning", "Warning", "Unauthorized or session expired.");
      store.dispatch("actions/setLoading", false);
    } else if (error.response.status === 404) {
      window.showAlert("warning", "Warning", "Not found.");
      store.dispatch("actions/setLoading", false);
    } else if (error.response.status === 403) {
      window.showAlert("warning", "Warning", "Session expired. Please sign in again.");
      store.dispatch("actions/setLoading", false);

      store.dispatch('auth/setUserDetails', null);
      store.dispatch('auth/setUserToken', null);
      store.dispatch('auth/setUserStep', null);

      router.push('/login');
    } else {
      //console.log(error.response);
      store.dispatch("actions/setLoading", false);
    }
  } else if (error.request) {
    store.dispatch("actions/setLoading", false);
  } else {
    store.dispatch("actions/setLoading", false);
  }

  // Do something with response error
  return Promise.reject(error)
})

Api.defaults.withCredentials = false

window.api = Api;
