export default {
    namespaced: true,
    state: {
        token: null,
        userDetails: null,
        step: null
    },
    
    getters: {
        token: state => state.token,
        userDetails: state =>  state.userDetails,
        step: state => state.step
    },

    actions: {
        setUserToken({ commit }, token) {
            commit('SET_TOKEN', token);
        },

        setUserDetails({ commit }, userDetails) {
            commit('SET_USER_DETAILS', userDetails)
        },

        setUserStep({ commit }, step) {
            commit('SET_USER_STEP', step);
        }
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },

        SET_USER_DETAILS(state, userDetails) {
            state.userDetails = userDetails;
        },

        SET_USER_STEP(state, step) {
            state.step = step;
        }
    }
}